<template>
    <section class="view contact">
        <view-title v-bind:title="$t('message.contact-us.title')"></view-title>

        <div class="container py-5">
            <div class="row mb-2">
                <div class="col-xs-2 col-md-4 text-right">
                    <font-awesome-icon icon="map-marker-alt"></font-awesome-icon>
                </div>
                <div class="col-xs-10 col-md-8">
                    <h5 class="text-left mb-0">
                        {{ $t("message.contact-us.address") }}
                    </h5>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-xs-2 col-md-4 text-right">
                    <font-awesome-icon icon="envelope"></font-awesome-icon>
                </div>
                <div class="col-xs-10 col-md-8">
                    <h5 class="text-left mb-0">
                        marketing@marierskincare.com
                    </h5>
                </div>
            </div>
            <div class="row d-flex justify-content-center mb-2">
                <div class="col-xs-2 col-md-4 text-right">
                    <font-awesome-icon icon="phone"></font-awesome-icon>
                </div>
                <div class="col-xs-10 col-md-8">
                    <h5 class="text-left mb-0">
                        3583 2928
                    </h5>
                </div>
            </div>
        </div>
        <view-title underline color="white" reveal-animation="fade-down"
                    v-bind:title="$t('message.contact-us.form-title')"></view-title>
        <div class="container pb-5">
            <form class="mb-5">
                <div class="form-row">
                    <div class="form-group col-md-3">
                        <label for="title">
                            {{ $t("message.contact-us.form-name-title") }}
                        </label>
                        <select id="title"
                                class="form-control rounded-0"
                                v-bind:disabled="userStatus === 'loggedIn'"
                                v-model="title">
                            <option v-bind:value="'miss'">
                                {{ $t("message.registration.title-miss") }}
                            </option>
                            <option v-bind:value="'ms'">
                                {{ $t("message.registration.title-ms") }}
                            </option>
                            <option v-bind:value="'mr'">
                                {{ $t("message.registration.title-mr") }}
                            </option>
                        </select>
                    </div>

                    <div class="form-group col-md-9">
                        <label for="name">
                            {{ $t("message.contact-us.form-name") }}
                        </label>
                        <input id="name"
                               type="text"
                               class="form-control rounded-0"
                               v-bind:disabled="userStatus === 'loggedIn'"
                               v-model.trim="name">
                    </div>

                    <!-- <div class="form-group col-md-4">
                        <label for="gender">
                            {{ $t("message.contact-us.form-gender") }}
                        </label>
                        <select id="gender"
                                class="form-control rounded-0"
                                v-model="gender">
                            <option value="" disabled selected>
                                {{ $t("message.contact-us.form-gender") }}
                            </option>
                            <option value="male">
                                {{ $t("message.contact-us.form-gender-male") }}
                            </option>
                            <option value="female">
                                {{ $t("message.contact-us.form-gender-female") }}
                            </option>
                        </select>
                    </div> -->
                </div>

                <div class="form-group">
                    <label for="email">
                        {{ $t('message.contact-us.form-email') }}
                    </label>
                    <input id="email"
                           type="email"
                           class="form-control rounded-0"
                           v-bind:disabled="userStatus === 'loggedIn'"
                           v-model.trim="email">
                </div>

                <div class="form-row">
                    <div class="form-group col-md-2">
                        <label for="countryCode">
                            {{ $t('message.contact-us.form-country-code') }}
                        </label>
                        <select id="countryCode"
                                class="form-control rounded-0"
                                v-bind:disabled="userStatus === 'loggedIn'"
                                v-model="countryCode">
                            <option v-bind:value="'+852'">+852</option>
                            <option v-bind:value="'+853'">+853</option>
                            <option v-bind:value="'+86'">+86</option>
                        </select>
                    </div>
                    <div class="form-group col-md-10">
                        <label for="phone">
                            {{ $t('message.contact-us.form-phone') }}
                        </label>
                        <input id="phone"
                               type="tel"
                               class="form-control rounded-0 "
                               maxlength="8"
                               v-bind:disabled="userStatus === 'loggedIn'"
                               v-model.trim="phone">
                    </div>
                </div>

                <div class="form-group">
                    <label for="content">{{ $t("message.contact-us.form-content") }}</label>
                    <textarea id="content"
                              class="form-control rounded-0"
                              rows="5"
                              v-model="content">
                    </textarea>
                </div>

                <b-form-checkbox v-model="acceptTC">
                    *{{ $t("message.contact-us.form-terms-checkbox") }}
                </b-form-checkbox>
            </form>
            <div class="d-flex justify-content-center align-items-center">
                <button class="btn btn-dark btn-block btn-submit"
                        v-bind:class="{ 'btn-loading': isLoading }"
                        v-bind:disabled="!allowSubmission"
                        v-on:click="sendMessage">
                    {{ $t("message.contact-us.form-send") }}
                </button>
            </div>
        </div>

        <b-modal hide-footer
                 centered
                 v-bind:title="$t('message.contact-us.form-complete-title')"
                 v-model="isSubmittedModalActive">
            {{ $t("message.contact-us.form-complete") }}
        </b-modal>

        <b-modal hide-footer
                 centered
                 v-bind:title="$t('message.contact-us.form-error-title')"
                 v-model="isErrorModalActive">
            {{ $t("message.contact-us.form-error") }}
        </b-modal>
    </section>
</template>

<script>
import createMessage from "../api/mall/createMessage";

import {
    BFormCheckbox,
    BModal,
} from "bootstrap-vue";

import ViewTitle from "../components/ViewTitle";

export default {
    name: "Contact",
    components: {
        ViewTitle,
        BFormCheckbox,
        BModal,
    },
    metaInfo () {
        return {
            title: this.$t("message.contact-us.title"),
            meta: [{
                "name": "description",
                "content": this.$t("message.contact-us.title"),
                "vmid": "description",
            }, {
                "property": "og:title",
                "content": this.$t("message.contact-us.title"),
                "template": "%s | marier Skincare 曼肌兒",
                "vmid": "og:title"
            }, {
                "property": "og:description",
                "content": this.$t("message.contact-us.title"),
                "vmid": "og:description"
            }, {
                "property": "og:type",
                "content": "website",
                "vmid": "og:type"
            }, /*{
                    "property": "og:image",
                    "content": "",
                    "vmid": "og:image"
                }*/]
        }
    },
    data () {
        return {
            isLoading: false,
            isSubmittedModalActive: false,
            isErrorModalActive: false,

            title: "",
            name: "",
            email: "",
            countryCode: "+852",
            phone: "",
            content: "",
            acceptTC: false,
        }
    },
    computed: {
        userStatus () {
            return this.$store.state.user.status;
        },

        allowSubmission () {
            return this.title &&
                this.name &&
                this.email &&
                this.countryCode &&
                this.phone &&
                this.content &&
                this.acceptTC;
        }
    },
    methods: {
        async sendMessage () {
            this.isLoading = true;
            let message = {
                "title": this.title,
                "name": this.name,
                "email": this.email,
                "countryCode": this.countryCode,
                "phone": this.phone,
                "content": this.content
            };

            let result;

            try {
                result = await createMessage(message);
            } catch (e) {
                console.log(e);
                this.isLoading = false;
                this.isErrorModalActive = true;
                return;
            }

            this.isLoading = false;
            this.isSubmittedModalActive = true;

            this.content = "";
            this.acceptTC = false;

            if (this.userStatus !== "loggedIn") {
                this.title = "";
                this.name = "";
                this.email = "";
                this.countryCode = "+852";
                this.phone = "";
            }
        }
    },
    mounted () {
        if (this.userStatus === "loggedIn") {
            this.title = this.$store.state.user.user.title;
            this.name = `${ this.$store.state.user.user.surname } ${ this.$store.state.user.user.name}`;
            this.email = this.$store.state.user.user.email;
            this.countryCode = this.$store.state.user.user.countryCode;
            this.phone = this.$store.state.user.user.phone;
        }
    },
    watch: {
        "userStatus" (newVal) {
            if (newVal === "loggedIn") {
                this.title = this.$store.state.user.user.title;
                this.name = `${ this.$store.state.user.user.surname } ${ this.$store.state.user.user.name}`;
                this.email = this.$store.state.user.user.email;
                this.countryCode = this.$store.state.user.user.countryCode;
                this.phone = this.$store.state.user.user.phone;
            } else {
                this.isLoading = false;
                this.title = "";
                this.name = "";
                this.email = "";
                this.countryCode = "+852";
                this.phone = "";
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.contact {
    color: rgba(113, 113, 113, 1);

    .btn-submit {
        max-width: 300px;
    }
}
</style>
