import { apiUrl, apiVersion, } from "../../config";

export async function createMessage (
    message
) {
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/mall/inbox`,
        {
            method: "POST",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(message),
        }
    );

    if (response.ok) {
        const message = await response.json();
        if (message) {
            return message;
        }
        throw "MALL CREATE MESSAGE error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default createMessage;
